import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from 'moment';

Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin
    },
    data: function() {
        return {
            pageTitle: this.$t('traitement.vente_fournisseur_client.page_titre'),

            vuetable: {
                moreParams: {},
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center'
                    // },
                    {
                        name: 'date_vente_direct_formatted',
                        title: this.$t('traitement.vente_fournisseur_client.date'),
                        sortField: 'date_vente_direct',
                        dataClass: 'text-center',
                    },
                    {
                        name: 'num_vente_direct',
                        title: this.$t('traitement.vente_fournisseur_client.numero'),
                        dataClass: 'text-center',
                        sortField: 'num_fa_client',
                    },
                    {
                        name: 'nom_fournisseur',
                        title: this.$t('traitement.vente_fournisseur_client.fournisseur'),
                        dataClass: 'text-left',
                        sortField: 'nom_fournisseur',
                    },
                    {
                        name: 'nom_client',
                        title: this.$t('traitement.vente_fournisseur_client.client'),
                        dataClass: 'text-left',
                        sortField: 'nom_client',
                    },
                    {
                        name: 'net_a_payer_fournisseur',
                        title: this.$t('traitement.vente_fournisseur_client.net_a_payer_frns'),
                        dataClass: 'text-right',
                        sortField: 'net_a_payer_fournisseur'
                    },
                    {
                        name: 'reste_a_payer_na',
                        title: this.$t('traitement.vente_fournisseur_client.reste_a_payer_frns'),
                        dataClass: 'text-right',
                        sortField: 'reste_a_payer_na'
                    },
                    {
                        name: 'net_a_payer_vente',
                        title: this.$t('traitement.vente_fournisseur_client.net_a_payer_client'),
                        dataClass: 'text-right',
                        sortField: 'net_a_payer_vente'
                    },
                    {
                        name: 'reste_a_payer_fa',
                        title: this.$t('traitement.vente_fournisseur_client.reste_a_payer_client'),
                        dataClass: 'text-right',
                        sortField: 'reste_a_payer_fa'
                    },
                    {
                        name: 'actions',
                        width: "110px",
                        dataClass: "text-center",
                        titleClass: 'text-center',
                    }

                ],
                sortOrder: [
                    { field: 'date_vente_direct', direction: 'asc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Date': 'date_vente_direct_formatted',
                    'Numero': 'num_vente_direct',
                    'Client': 'nom_client',
                    'Total Montant HT': 'total_ht',
                    'Total Montant TVA': 'total_tva',
                    'Total Montant TTC': 'total_ttc',
                    'Total Remise': 'total_remise',
                    'Total Montant A payer': 'total_a_payer',
                    'Commentaire': 'commentaire',
                    'Date Heure Enregistrement': 'date_heure_enregistrement'
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            motCle: "",
            crudform: {
                id: "",
                num_vente_direct: "",
                date_vente_direct_formatted: "",
                clients_id: "",
                fournisseurs_id: "",
                magasin_id: "",
                commentaire: "",
                total_tva: "",
                total_ht: "",
                total_ttc: "",
                total_remise: "",
                total_a_payer: "",
                details: []
            },
            crudmodaltitle: this.$t('traitement.vente_fournisseur_client.modal.ajouter_vente'),
            listdata: {
                familles: [],
                articles: [],
                clients: [],
                fournisseurs: [],
                magasin_defaut: "",
            },
            criterearticle: {
                famille: "",
                search: ""
            },
            client: {},
            showDetail: true,
            point_de_ventes: [],
            point_de_ventes_id: "",
            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            dateOptions: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            filtreDate: {
                dateDebut: "",
                dateFin: ""
            },

        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        editRow(rowData) {
            this.crudmodaltitle = this.$t('traitement.vente_fournisseur_client.modal.editer_vente');
            //alert("You clicked edit on" + JSON.stringify(rowData))
            axios.get(this.BASE_URL + "/ventedirect/get/" + rowData.id).then(response => {
                //console.log(response.data[0]);

                this.crudmodaltitle = this.crudmodaltitle + " | " + response.data[0].num_fa_client + " / " + response.data[0].num_na_fournisseur;
                this.crudform = response.data[0];
            });
            this.openModal();
        },
        deleteRow(rowData) {
            //alert("You clicked delete on" + JSON.stringify(rowData));
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment supprimer cette ligne?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Supprimer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        axios.post(that.BASE_URL + "/ventedirect/delete/" + rowData.id).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log(response.data.message);
                                that.setFilter();
                                Vue.$toast.open({
                                    message: response.data.message,
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                })

        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            this.vuetable.moreParams.point_de_ventes_id = this.point_de_ventes_id;
            this.vuetable.moreParams.startDate = this.filtreDate.dateDebut;
            this.vuetable.moreParams.endDate = this.filtreDate.dateFin;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        loadPointDeVente() {
            axios.get(this.BASE_URL + "/pointvente/getall/").then(response => {
                this.point_de_ventes = response.data;
            });
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("myCustomModal");
        },
        closeModal() {
            this.$bvModal.hide("myCustomModal");
        },
        addRow() {
            this.crudmodaltitle = this.$t('traitement.vente_fournisseur_client.modal.ajouter_vente');
            this.showDetail = true;
            this.crudform = {
                id: "",
                num_vente_direct: "",
                date_vente_direct_formatted: moment().format('DD/MM/YYYY'),
                clients_id: "",
                fournisseurs_id: "",
                magasin_id: "",
                commentaire: "",
                total_tva: "",
                total_ht: "",
                total_ttc: "",
                total_remise: "",
                total_a_payer: "",
                details: []
            };
            if (this.listdata.magasin_defaut != '' && this.listdata.magasin_defaut != null) {
                this.crudform.magasin_id = this.listdata.magasin_defaut + '';
            }

            this.openModal();
        },
        onSubmit() {
            var that = this;
            this.$bvModal.msgBoxConfirm('Voulez-vous vraiment continuer l\'enregistrement?', {
                    title: 'Confirmation',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'Continuer',
                    cancelTitle: 'Annuler',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    console.log(value);
                    if (value == true) {
                        var link = this.crudform.id == "" ? that.BASE_URL + "/ventedirect/addaction" : that.BASE_URL + "/ventedirect/editaction";
                        axios.post(link, $("#formulaire").serialize()).then(function(response) {
                            console.log(response);
                            //this.message_reponse = response.data.msg;
                            if (response.data.error === "") {
                                console.log("Enregistrement effectué avec succès!");
                                if (!(that.crudform.id > 0)) {
                                    var row = {
                                        id: response.data.id
                                    };
                                    that.editRow(row);
                                }
                                // that.closeModal();
                                that.setFilter();
                                Vue.$toast.open({
                                    message: 'Enregistrement effectué avec succès!',
                                    type: 'success',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            } else {
                                console.log("Erreur");
                                Vue.$toast.open({
                                    message: response.data.error,
                                    type: 'error',
                                    position: 'top-right'
                                        // all of other options may go here
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                    // An error occurred
                });
        },
        fetchData: function() {
            axios.get(this.BASE_URL + "/ventedirect/fetchdata").then(response => {
                this.listdata.familles = response.data.familles;
                this.listdata.clients = response.data.clients;
                this.listdata.magasins = response.data.magasins;
                this.listdata.fournisseurs = response.data.fournisseurs;
                this.listdata.magasin_defaut = response.data.magasin_defaut;

                if (this.listdata.magasin_defaut != '' && this.listdata.magasin_defaut != null) {
                    this.crudform.magasin_id = this.listdata.magasin_defaut + '';
                }
            });
        },
        setCritereArticleFilter: function() {
            axios.get(this.BASE_URL + "/articles/fetchlimitted", {
                params: {
                    famille: this.criterearticle.famille,
                    search: this.criterearticle.search,
                    magasin: this.crudform.magasin_id
                }
            }).then(response => {

                this.listdata.articles = response.data;
            });
        },
        resetCritereArticleFilter: function() {
            this.criterearticle.famille = "";
            this.criterearticle.search = "";


            this.setCritereArticleFilter();
        },
        pushrow: function(articleData) {
            console.log(articleData)
            var exist = false;
            for (var i = 0; i < this.crudform.details.length; i++) {
                var item = this.crudform.details[i];
                if (item.articles_id == articleData.articles_id) {
                    Vue.$toast.open({
                        message: "Cet article a déjà été choisit",
                        type: 'warning',
                        position: 'top-right'
                    });
                    exist = true;
                    break;
                }
            }


            if (!exist) {
                this.crudform.details.push({
                    articles_id: articleData.articles_id,
                    ref_article: articleData.ref_article,
                    designation: articleData.designation,
                    quantite: "",
                    pu_fournisseur: articleData.prix_revient,
                    montant_fournisseur: "",
                    montant_fournisseur_sans_remise: "",
                    remise_fournisseur: "",
                    pu_vente: articleData.pu_vente,
                    montant_vente: "",
                    montant_vente_sans_remise: "",
                    remise_vente: "",
                });
            }
            this.updateMontant();
        },
        delRow: function(i) {
            this.crudform.details.splice(i, 1);
            this.updateMontant();
        },

        updateMontant: function() {
            this.crudform.total_sans_remise_fournisseur = 0;
            this.crudform.total_fournisseur = 0;
            this.crudform.net_a_payer_fournisseur = 0;
            this.crudform.total_sans_remise_vente = 0;
            this.crudform.total_vente = 0;
            this.crudform.net_a_payer_vente = 0;


            for (var i = 0; i < this.crudform.details.length; i++) {
                if (this.crudform.details[i].pu_vente == null || this.crudform.details[i].pu_vente == '') {
                    this.crudform.details[i].pu_vente = 0;
                }
                if (this.crudform.details[i].pu_fournisseur == null || this.crudform.details[i].pu_fournisseur == '') {
                    this.crudform.details[i].pu_fournisseur = 0;
                }
                if (this.crudform.details[i].quantite == null || this.crudform.details[i].quantite == '') {
                    this.crudform.details[i].quantite = 0;
                }
                if (this.crudform.details[i].remise_fournisseur == '' || this.crudform.details[i].remise_fournisseur == null) {
                    this.crudform.details[i].remise_fournisseur = 0;
                }
                if (this.crudform.details[i].remise_vente == '' || this.crudform.details[i].remise_vente == null) {
                    this.crudform.details[i].remise_vente = 0;
                }

                this.crudform.details[i].montant_fournisseur_sans_remise = parseFloat(this.crudform.details[i].pu_fournisseur) * parseFloat(this.crudform.details[i].quantite);
                this.crudform.details[i].montant_fournisseur = this.crudform.details[i].montant_fournisseur_sans_remise - this.crudform.details[i].remise_fournisseur;
                this.crudform.details[i].montant_vente_sans_remise = parseFloat(this.crudform.details[i].pu_vente) * parseFloat(this.crudform.details[i].quantite);
                this.crudform.details[i].montant_vente = this.crudform.details[i].montant_vente_sans_remise - this.crudform.details[i].remise_vente;


                // Mettre a jour les totaux
                this.crudform.total_sans_remise_fournisseur += this.crudform.details[i].montant_fournisseur_sans_remise;
                this.crudform.total_fournisseur += this.crudform.details[i].montant_fournisseur;
                this.crudform.total_sans_remise_vente += this.crudform.details[i].montant_vente_sans_remise;
                this.crudform.total_vente += this.crudform.details[i].montant_vente;

            }


            this.crudform.net_a_payer_fournisseur = this.crudform.total_fournisseur - this.crudform.remise_fournisseur;
            this.crudform.net_a_payer_vente = this.crudform.total_vente - this.crudform.remise_vente;

        },
        getClient: function(id_client) {
            if (id_client == '' || id_client == null) {
                this.client = {};
            } else {
                axios.get(this.BASE_URL + "/clients/get/" + id_client).then(response => {
                    this.client = response.data[0];

                });
            }

        },
        onRowClass(dataItem, index) {
            if (index == "") console.log("");
            console.log(index);
            var retour = "";
            retour = dataItem.reste_a_payer_na > 0 || dataItem.reste_a_payer_fa > 0 ?
                'text-danger' :
                ''

            return retour;
        },

    },
    computed: {
        articlesList: function() {
            var that = this;
            return this.listdata.articles.filter(function(article) {
                if (article.ref_article.toLowerCase().includes(that.criterearticle.search.toLowerCase()) || article.designation.toLowerCase().includes(that.criterearticle.search.toLowerCase())) {
                    return true;
                }
                return false;
            });
        }
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);

        this.motCle = "";
        this.criteriacol = "";

        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;


        this.loadPointDeVente();
        this.fetchData();
        this.setCritereArticleFilter();

    },
    watch: {
        'criterearticle.famille': function() {
            this.setCritereArticleFilter();
        },
        'crudform.clients_id': function(newvalue) {
            this.getClient(newvalue);
        },
        'crudform.magasin_id': function() {
            this.setCritereArticleFilter();
        },

    },
    mounted: function() {
        // $("input").on("keydown", function(e) {
        //     e.preventDefault();
        //     return false;
        // });
        if (this.$route.params.type == 'add') {
            this.addRow();
        }
        if (this.$route.name == 'Ventedirectgenerate') {
            this.addRow();
            this.crudmodaltitle = "Generer Vente fournisseurs/clients à partir d'une bon de commande fournisseur";
            axios.get(this.BASE_URL + "/boncommandefournisseur/generatevente/" + this.$route.params.id_bon_commande).then(response => {

                this.crudform.fournisseurs_id = response.data[0].fournisseurs_id;
                this.crudform.magasin_id = response.data[0].magasin_id;
                this.crudform.commentaire = response.data[0].commentaire;
                this.crudform.remise_fournisseur = response.data[0].remise;
                this.crudform.total_tva = response.data[0].total_tva;
                this.crudform.total_ht = response.data[0].total_ht;
                this.crudform.total_ttc = response.data[0].total_ttc;
                this.crudform.total_remise = response.data[0].total_remise;
                this.crudform.total_a_payer = response.data[0].total_a_payer;
                this.crudform.bon_commande_id = response.data[0].id;
                this.crudform.details = response.data[0].details;
                this.updateMontant();


            });
        }
    }
}